import http from "@/services/api";
import { mountUrlWP, mountUrlApiTransparencia } from "@/utils/mountURL";

export function getAllPosts(query = ""): Promise<any> {
  const url = mountUrlWP(`/get_search_results/?search=${query}&count=100`);
  return http
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getAllDocumentos(query = ""): Promise<any> {
  const url = mountUrlApiTransparencia(
    `/documento?_orderBy=datacadastro&_order=desc&_page=1&_limit=100&status=1&titulo=%${query}%`
  );
  return http
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getAllLicitacoes(query = ""): Promise<any> {
  const url = mountUrlApiTransparencia(
    `/licitacao?_orderBy=dataabertura&_order=desc&_page=1&_limit=100&objeto=%${query}%`
  );
  return http
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
