
import { Component, Vue, Watch } from "vue-property-decorator";
// layouts
import Layout from "@/layouts/basic/basic.vue";

import {
  getAllPosts,
  getAllDocumentos,
  getAllLicitacoes,
} from "@/services/search";
import { sendGetAllLicitacoes } from "../modules/licitacoes/services/licitacoes";

@Component({
  components: {
    Layout,
  },
})
export default class Home extends Vue {
  inputSeach = "";
  lastSearchParams = "";
  isLoading = false;
  activeTab = 0;
  showBooks = false;

  listPosts: any[] = [];
  countPosts = 0;
  listDocumentos: any[] = [];
  countDocumentos = 0;
  listLicitacoes: any[] = [];
  countLicitacoes = 0;

  get searchParam(): string {
    if (this.$route.query.q == undefined) {
      return "";
    }
    return this.$route.query.q as string;
  }

  clickBtnBuscar(): void {
    if (this.inputSeach != "") {
      if (this.inputSeach == this.lastSearchParams) {
        this.sendAllSearchs(this.lastSearchParams);
      } else {
        this.$router.push(`/busca?q=${this.inputSeach}`);
      }
    }
  }

  sendAllSearchs(search: string): void {
    this.sendGetAllPosts(search);
    this.sendGetAllDocumentos(search);
    this.sendGetAllLicitacoes(search);
  }

  sendGetAllPosts(q: string): void {
    this.setIsLoading(true);
    this.listPosts = [];
    this.countPosts = 0;
    getAllPosts(q)
      .then((response) => {
        this.lastSearchParams = q;
        this.listPosts = response.data.posts;
        this.countPosts = response.data.count_total;
        this.setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        this.setIsLoading(false);
      });
  }

  sendGetAllDocumentos(q: string): void {
    this.setIsLoading(true);
    this.listDocumentos = [];
    getAllDocumentos(q)
      .then((response) => {
        this.lastSearchParams = q;
        this.listDocumentos = response.data.list;
        this.countDocumentos = response.data.count;
        this.setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        this.setIsLoading(false);
      });
  }

  sendGetAllLicitacoes(q: string): void {
    this.setIsLoading(true);
    this.listLicitacoes = [];
    getAllLicitacoes(q)
      .then((response) => {
        this.lastSearchParams = q;
        this.listLicitacoes = response.data.list;
        this.countLicitacoes = response.data.count;
        this.setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        this.setIsLoading(false);
      });
  }

  setIsLoading(newValue: boolean): void {
    this.isLoading = newValue;
  }

  onClickPost(post: any) {
    console.log(post);

    if (post.type == "post") {
      console.log(`/noticia/${post.slug}`);
      this.$router.push(`/noticia/${post.slug}`);
    } else {
      console.log(`/pagina/${post.slug}`);
      this.$router.push(`/pagina/${post.slug}`);
    }
  }

  @Watch("$route.query.q", {
    immediate: true,
    deep: false,
  })
  change(newVal: string): void {
    this.inputSeach = this.searchParam;
    if (this.inputSeach != "") {
      // send request
      console.log("send request", newVal);
      this.sendAllSearchs(newVal);
    }
  }
}
